<header id="page-topbar">
    <div class="navbar-header" style="background-image: url('assets/images/corpicotrabajo3.JPG'); background-size: cover;">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a routerLink="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/LogoInicio.JPG" alt="" height="80">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/LogoInicio.JPG" alt="" height="80">
                    </span>
                </a>

                    <a routerLink="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/LogoInicio.JPG" alt="" height="80">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/LogoInicio.JPG" alt="" height="80">
                    </span>
                </a>
            </div>

        </div>

        <div class="d-flex">
            <button (click)="logout()" type="button" class="btn btn-homey-cancel bx2" id="page-header-user-dropdown" style="color: rgb(255, 255, 255); background-color: #ffffff;">
                <i class="bx bxs-log-out" style="margin-right: 5px; background-color: #4147C3"></i>
                <strong>Cerrar sesión</strong>
            </button>
             <!--   <button (click)="logout()" type="button" class="btn btn-homey-cancel bx2" id="page-header-user-dropdown">
                    <strong>Cerrar sesión</strong>
                </button>-->
        </div>
    </div>
</header>

<div class="account-pages my-1 pt-sm-3 d-flex justify-content-center align-items-center min-vh-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-6 justify-content-center">
                <div class="card overflow-hidden justify-content-center" style="background-image: url('assets/images/FondoInicio.png'); background-size: cover;">
                    <div class="bg-soft d-flex justify-content-center align-items-center" style="min-height: 200px;">
                        <div class="p-6 justify-content-center">
                                <img src="assets/images/LogoInicio.png" alt="" class="img-fluid rounded-circle" style="width: 200px; height: 200px">
                        </div>
                    </div>             
                    <div class="card-body d-flex justify-content-center align-items-center">
                        <div class="p-2">
                            <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                                <div class="mb-3" style="width: 365px;">
                                    <div class="input-group auth-pass-inputgroup justify-content-center align-items-center" >
                                        <i class="mdi mdi-email-outline" style="font-size: 24px; color: white;"></i>
                                        <input type="email" formControlName="user_email" class="form-control" id="email" placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && f.user_email.errors }" />
                                        <div *ngIf="submitted && f.user_email.errors" class="invalid-feedback">
                                            <div *ngIf="f.user_email.errors.required">Email requerido</div>
                                            <div *ngIf="f.user_email.errors.email">Email no válido</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3" style="width: 365px;">
                                    <div class="input-group auth-pass-inputgroup justify-content-center align-items-center">
                                        <i class="mdi mdi-lock-outline" style="font-size: 24px; color: white;"></i>
                                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="user_password" placeholder="Enter password"
                                               [ngClass]="{ 'is-invalid': submitted && f.user_password.errors }" aria-label="Password"
                                               aria-describedby="password-addon">
                                           
                                        <div *ngIf="submitted && f.user_password.errors" class="invalid-feedback">
                                          <span *ngIf="f.user_password.errors.required">Password is required</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 d-grid" style="width: 265px;" >
                                    <button style="background: #007E3D" class="btn btn-primary" type="submit">INICIAR SESIÓN</button></div>
                                <div class="mt-4 text-center">
                                    <a routerLink="/account/reset-password" class="text-white"><i class="mdi mdi-lock mr-1"></i> Recuperar contraseña</a>
                                </div>
                            </form>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- end container -->
</div>
<!-- end page -->

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { LanguageService } from '../../../core/services/language.service';

import { environment } from '../../../../environments/environment';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
/**
 * Login-2 component
 */
export class Login2Component implements OnInit {

  fieldTextType: boolean;
  fieldTextType2: boolean;

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  constructor(public languageService: LanguageService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }
  loginForm: FormGroup;
  submitted = false;
  error = '';
  returnUrl: string;

  // set the currenr year
  year: number = new Date().getFullYear();

  listLang = {
    'es-AR': 'es',
    'en-US': 'en',
    'pt-BR': 'pt'
  };

  ngOnInit() {
    document.body.classList.add('auth-body-bg')
    this.loginForm = this.formBuilder.group({
      user_email: ['', [Validators.required, Validators.email]],
      user_password: ['', [Validators.required]],
    });

    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.authenticationService.login(this.f.user_email.value, this.f.user_password.value).subscribe((res:any)=>{
        this.languageService.setLanguage(this.listLang['es-AR']);
        sessionStorage.setItem('authUser', JSON.stringify(res));
        this.router.navigate(['/']);
      }, (error) => {
        console.log('Error', error)
        this.error = error;
      })
    }
  }
}

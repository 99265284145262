import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { getFirebaseBackend } from '../../authUtils';

@Injectable({ providedIn: 'root' })

export class CommonService {

    @Output() breadcrumbEvent: EventEmitter<string> = new EventEmitter();
    @Output() dataEvent: EventEmitter<string> = new EventEmitter();

    breadcrumb:string = "";
    data:any = {};

    constructor(private _http:HttpClient) {
    }

    getLanguages(params: any): Observable<any> {
       return this._http.get(`${environment.apiUrl}/api/languages`, { params });
    }

    getNotifications(): Observable<any> {
      return this._http.get(`${environment.apiUrl}/api_v2/notifications`);
    }

    readNotifications(id: string) {
        return this._http.put(`${environment.apiUrl}/api_v2/notifications/${id}`, {"notification_read": "TRUE"});
    }
    
    readAllNotifications() {
        return this._http.put(`${environment.apiUrl}/api/notifications/all_read`, {});
    }

    breadcrumbGlobal(breadcrumb:string){
      this.breadcrumb = breadcrumb;
      this.breadcrumbEvent.emit(this.breadcrumb);
    }

    setData(data:any){
      this.data = data;
      this.dataEvent.emit(this.data);
    }

}


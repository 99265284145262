import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor(private _http:HttpClient) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
//        return getFirebaseBackend().getAuthenticatedUser();
      if (!sessionStorage.getItem('authUser')) {
        return null;
      }
      return JSON.parse(sessionStorage.getItem('authUser'));
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(user_email: string, user_password: string) {
        return this._http.post(`${environment.apiUrl}/api/users/login`, {user_email, user_password});
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(item: any) {
        return this._http.post(`${environment.apiUrl}/api_v2/users/register`, item);
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        sessionStorage.removeItem('authUser');
    }
}


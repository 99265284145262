import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  ElementRef,
  OnInit,
  Attribute
} from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Directive({
  selector: '[hasPermission]'
})

export class hasMenuPermissionDirective implements OnInit {

  private currentUser;
  private permissions;
  private logicalOp = 'AND';
  private isHidden = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: AuthenticationService
  ) {

  }

  ngOnInit() {
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if(this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    this.currentUser = this.userService.currentUser();
    let isAllowed = false;
    if (this.currentUser.user_permissions && this.currentUser.user_permissions.length != 0) {
      if (this.currentUser.user_permissions.transversal[this.permissions.resource]
        && this.currentUser.user_permissions.transversal[this.permissions.resource].indexOf(this.permissions.permission) >= 0) {
        isAllowed = true;
      }
    }
    return isAllowed;
  }
}

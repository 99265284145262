import { MenuItem } from './menu.model';

export const MENU_SELLER: MenuItem[] = [
    {
        id: 1,
        label: 'Ordenes de trabajo',
        icon: 'Ordenest',
        // permission: ['Administrador'],
        subItems: [
            {
                id: 1,
                label: 'Órdenes activas',
                link: 'administration/ot',
                parentId: 1,
                queryParams: {
                    ot_estado: 'A'
                },
                search: 'ot_estado=A',
            },
            {
                id: 2,
                label: 'Órdenes a verificar',
                link: 'administration/ot',
                parentId: 1,
                queryParams: {
                    ot_estado: 'CHECK'
                },
                search: 'ot_estado=CHECK',
            },
            {
                id: 3,
                label: 'Órdenes no ejecutadas',
                link: 'administration/ot',
                parentId: 1,
                queryParams: {
                    ot_estado: 'N'
                },
                search: 'ot_estado=N',
            },
            {
                id: 4,
                label: 'Órdenes cerradas',
                link: 'administration/ot',
                parentId: 1,
                queryParams: {
                    ot_estado: 'C'
                },
                search: 'ot_estado=C',
            }
        ]
    },
    {
        id: 2,
        label: 'Reportes',
        icon: 'Analitycs',
        // permission: ['Administrador'],
        subItems: [
            {
                id: 2,
                label: 'Ordenes técnicas',
                link: 'otreport',
                parentId: 2
            },
            {
                id: 1,
                label: 'Sesiones',
                link: 'sessions',
                parentId: 2
            }
        ]
    },
    {
        id: 3,
        label: 'Guardias',
        link: 'calendar',
        icon: 'Guardias',
        // permission: ['Administrador', ],
    },
    {
        id: 4,
        label: 'Configuración',
        icon: 'Configuración',
        permission: ['Administrador'],
        subItems: [
            {
                id: 7,
                label: 'Cuadrillas',
                link: 'administration/cuadrillas',
                parentId: 4
            },
            {
                id: 2,
                label: 'Empresas contratistas',
                link: 'administration/emc',
                parentId: 4
            },
            {
                id: 4,
                label: 'Motivos de trabajo',
                link: 'administration/mot',
                parentId: 4
            },
            {
                id: 6,
                label: 'Operarios',
                link: 'administration/ope',
                parentId: 4
            },
            {
                id: 5,
                label: 'Tipo de trabajos',
                link: 'administration/tit',
                parentId: 4
            },
            {
                id: 3,
                label: 'Trabajos realizados',
                link: 'administration/works',
                parentId: 4
            },
            {
                id: 1,
                label: 'Usuarios',
                icon: 'Productos',
                link: 'administration/users',
                parentId: 4
            }
        ]
    }
];

